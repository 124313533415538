@import 'src/assets/diez-ui-web/static/styles';

.shepherd-content {
  .shepherd-header {
    background-color: $colors-primary-dark !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      @include typography-h4;
      color: white;
      font-weight: bold;
      margin-bottom: 0;
      text-align: center;
    }
  }


  .shepherd-text {
    h4 {
      @include typography-sub-copy;
    }

    p {
      @include typography-body;

      &.cta {
        font-weight: bold;
        color: $colors-primary-dark;
      }
    }
  }

  .shepherd-button {
    font-family: "Roboto-Regular",sans-serif !important;

    &.stay-here,
    &.return-to-editor {
      padding: 0.5rem !important;
    }

    &.advance {
      background: $colors-primary-dark !important;
      color: white;
    }
  }

  .dismiss-forever {
    background: none;
    font-size: 0.8em;
    color: $colors-accent !important;
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;

    &:hover {
      background: none;
    }
  }
}