// Proxima Nova Font
@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/proxima-ssv/Proxima-Nova-Thin.otf') format('OpenType');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/proxima-ssv/ProximaNova-Regular.otf') format('OpenType');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/proxima-ssv/Proxima-Nova-Bold.otf') format('OpenType');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/proxima-ssv/Proxima-Nova-Extrabold.otf') format('OpenType');
}

// Red Hat Text Font
@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/red-hat-text/static/RedHatText-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/red-hat-text/static/RedHatText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/red-hat-text/static/RedHatText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/red-hat-text/static/RedHatText-Bold.ttf') format('truetype');
}
