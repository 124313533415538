@import 'src/assets/diez-ui-web/static/styles';

* {
  margin: 0;
  padding: 0;
}

.internal-page {
  max-width: 1280px;
  margin: 0 auto;
}

.mat-icon-button.small {
  $size: 30px;
  height: $size;
  width: $size;
  line-height: $size / 2;

  mat-icon {
    height: $size * 0.6;
    width: $size * 0.6;
    line-height: $size * 0.6;
    font-size: $size * 0.6;
  }
}

.mat-icon-button.tiny {
  $size: 20px;
  height: $size;
  width: $size;
  line-height: ($size / 2) - 1px;

  mat-icon {
    height: $size * 0.6;
    width: $size * 0.6;
    line-height: $size * 0.6;
    font-size: $size * 0.6;
  }
}

p.info {
  font-size: 0.9em;
  font-style: italic;
}

.small-stage {
  margin: 20vh auto 0;
  width: 45%;
  min-width: 400px;
  max-width: 95%;
  text-align: center;
}

.flex-spacer {
  flex: 1 1 auto;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden;
  pointer-events: none;
}

.clickable {
  cursor: pointer;

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

a.clickable {
  color: $colors-accent;

  &.disabled {
    color: lightgray;
  }
}

.ng-scrollbar {
  --scrollbar-thumb-color: #{$colors-accent};
}

.columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .column {
    flex: 1;
  }
}

button.disabled {
  opacity: 0.6;
  cursor: initial !important;
}

.action-bar,
[mat-dialog-actions] {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end !important;
}

.img-wrap,
.media-wrapper {
  width: 100%;

  img,
  video {
    max-width: 100%;
    max-height: 100%;
  }
}

.mat-snack-bar-container {
  // need to vertically move snackbars up slightly
  // to avoid them from preventing clicking the
  // video controls on event attend page.
  transform: translateY(-50px) !important;
}

// Workaround for Apple's highmindedness
/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px !important; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="url"]{ font-size: 16px !important; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px !important; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select, textarea, input[type="text"], input[type="password"],
  input[type="datetime"], input[type="datetime-local"],
  input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"],
  input[type="tel"], input[type="url"]{ font-size: 16px !important; }
}

