@import "src/assets/diez-ui-web/static/styles";

$header-height: 32px;

$full-header-height: $header-height + ($layout-values-spacing-medium-px * 2);

.avatar {
  height: var(--avatar-size, 32px);
  width: var(--avatar-size, 32px);
  border-radius: calc(var(--avatar-size, 32px) / 2);
  border: 1px solid $colors-accent;
  overflow: hidden;
}

.mini-header {
  display: flex;
  flex-direction: row;
  height: $header-height;
  margin: $layout-values-spacing-medium-px auto;
  position: relative;
  z-index: 1;
}

// Drag and drop base styles
// Still need to add final style to individual element
.cdk-drag-preview,
.ng-sg-active {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder,
.ng-sg-placeholder {
  opacity: 0;
}

.cdk-drag-animating,
.ng-sg-dropped {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ng-sg-selected {
  background-color: rgba($colors-accent, 0.2) !important;
}

.info-tooltip {
  position: relative;
  font-size: 1rem;
  margin-left: 5px;
  top: 4px;
  color: $colors-primary-dark;
  opacity: 0.7;
  transition: all 0.3s ease-in;

  &:hover {
    opacity: 1;
  }
}

.loader {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    @include typography-subheading;
    margin-top: $layout-values-spacing-large-px;
  }
}

.uploader-and-preview {
  width: calc(100% - 250px);
  height: 120px;
  margin-bottom: $layout-values-spacing-medium-px * 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .uploader {
    flex: 3;
    margin-right: $layout-values-spacing-large-px;

    app-cloudinary-uploader {
      height: 100%;
    }
  }

  .preview {
    flex: 1;

    .image-wrapper,
    .media-wrapper {
      border: 1px dashed $colors-light-border;
      border-radius: 8px;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      mat-icon {
        opacity: 0.6;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.popover-content {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 5px;
  display: block;
  width: 400px;
  box-shadow: 0 0 2px 2px $colors-light-border;

  ul,
  li {
    list-style: none;
  }

  li {
    margin-bottom: 10px;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.initials {
  @include typography-text;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs {
  height: var(--tab-height, 70px);
  border-radius: $layout-values-spacing-medium-px * 0.5;
  border: 1px solid $colors-light-border;
  display: flex;
  flex-direction: row;
  margin-bottom: $layout-values-spacing-small-px;

  .tab {
    @include typography-sub-copy;
    flex: 1;
    height: 100%;
    color: $colors-primary-dark;
    text-align: center;
    line-height: var(--tab-height, 70px);
    cursor: pointer;
    border-radius: $layout-values-spacing-medium-px * 0.5;

    &.active {
      background-color: $colors-primary-dark;
      color: white;
      font-weight: bold;
    }

    &.chat {
      .mat-badge-content {
        background-color: $colors-accent;
      }
    }
  }
}

// Button styles
.btn {
  &,
  &.mat-flat-button,
  &.mat-stroked-button {
    background-color: transparent;
    padding: 12px;
    border: 1px solid transparent;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    outline: none;

    &:focus,
    &:hover {
      outline: none;
    }

    &.btn-filled {
      background-color: #1b75bc;
      color: #fff;
    }

    &.btn-outlined {
      background-color: #fff;
      color: #1b75bc;
      border: 1px solid #1b75bc;
    }
  }

  &-link {
    background-color: transparent;
    padding: 12px;
    border: 0;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    outline: none;
    color: #1b75bc;
  }
}

// Background icon styles
.bg-icon {
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center center;
}

.mat-icon {
  vertical-align: middle;
}

.organization-setting-dialog-panel {
  .mat-dialog-container {
    padding: 32px 0;
    border-radius: 20px;
  }

  .mat-dialog-content {
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 440px);
  }
}

.subscribing-organization-dialog-panel,
.unsubscribing-organization-dialog-panel {
  .mat-dialog-container {
    border-radius: 20px;
  }
}


.select-option-panel {
  .mat-option {
    font-family: 'Red Hat Text';
  }
}

.event-action-tooltip {
  padding: 8px 12px;
  margin-bottom: 12px;
  border-radius: 0;
  background-color: #202532;
  color: #fff;
  font-family: 'Proxima Nova';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.break-line {
  display: block;
  margin-right: 5px;
}

// Schedule sessions drag preview styles
.subevent.cdk-drag-preview {
  background: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #c9c9c9;
  display: flex;
  flex-direction: row;
  color: $colors-primary-darker;
  position: relative;
  padding: ($layout-values-spacing-medium-px * 0.5) $layout-values-spacing-medium-px;
  align-items: center;
  max-height: 100px;
  overflow: hidden;

  .name {flex: 0 0 40%;}
  .date {flex: 0 0 30%;}
  .hosts {flex: 0 0 0%;}
  .media {flex: 0 0 30%;}

  .draggable-btn {
    position: absolute;
    right: 44px;
    z-index: 1;
    opacity: 0.3;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.8;
    }
  }

  .action-menu {
    position: absolute;
    right: $layout-values-spacing-small-px;
  }

  .name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h4 {
      font-family: "Metropolis-Regular", sans-serif;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 29px; // no idea why this is necessary, but it is
    }

    a {
      color: $colors-primary-darker;
      margin-right: $layout-values-spacing-medium-px;
    }
  }

  .date {
    .day {
      font-size: 0.8rem;
      margin-bottom: 0;
    }

    .timing {
      font-size: 0.7rem;
      color: #929292;
      margin-bottom: 0;
    }
  }

  &.editing {
    max-height: 500px;

    app-subevent-editor {
      width: 100%;
    }
  }
}


// quill editor
/** All toolbar buttons are inside of .ql-formats */
.ql-formats button {
  position: relative;

  /** Set a tooltip with css pseudo-elements, when buttons are hover, active or focus  */
  &:hover::after,
  &:active::after,
  &:focus::after {
    background: #0d1e42;
    color: white;
    padding: 0.5em;
    border-radius: 0.4em;
    top: -120%;
    left: -10px;
    z-index: 999999;
    position: absolute;
    font-size: 12px;
  }
}
/** Set tooltip content for bold button **/
.ql-bold {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Bold";
  }
}
.ql-italic {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Italic";
  }
}
.ql-underline {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Underline";
  }
}
.ql-strike {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Strikeout";
  }
}
.ql-link {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Hyperlink";
  }
}
.ql-blockquote {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Quote";
  }
}
.ql-list[value="bullet"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Bulleted List";
    top: -200%;
  }
}
.ql-list[value="ordered"] {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Numbered List";
    top: -200%;
  }
}
.ql-code-block {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Code Block";
  }
}
.ql-clean {
  &:hover::after,
  &:active::after,
  &:focus::after {
    content: "Clear Formatting";
  }
}
