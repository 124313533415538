@font-face {
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: lighter;
  src: local('SF Pro Display Regular'), url('/assets/diez-ui-web/static/assets/SFPRODISPLAYREGULAR.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display Medium'), url('/assets/diez-ui-web/static/assets/SFPRODISPLAYMEDIUM.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro';
  font-style: italic;
  font-weight: lighter;
  src: local('SF Pro Display Light Italic'), url('/assets/diez-ui-web/static/assets/SFPRODISPLAYLIGHTITALIC.woff') format('woff');
}

@font-face {
  font-family: 'SF Pro';
  font-style: normal;
  font-weight: bold;
  src: local('SF Pro Display Bold'), url('/assets/diez-ui-web/static/assets/SFPRODISPLAYBOLD.woff') format('woff');
}


@font-face {
  font-family: 'SF Pro';
  font-style: italic;
  font-weight: bold;
  src: local('SF Pro Display Heavy Italic'), url('/assets/diez-ui-web/static/assets/SFPRODISPLAYHEAVYITALIC.woff') format('woff');
}

$colors-white: hsla(0, 0%, 100%, 1);
$colors-black: hsla(240, 100%, 3.1372549019607843%, 1);
$colors-primary-dark: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
$colors-primary-darker: hsla(214.73684210526315, 24.05063291139241%, 15.490196078431373%, 1);
$colors-primary: #1b75bc;
$colors-accent: #03a8df;
$colors-background: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
$colors-background-hint: hsla(0, 0%, 100%, 0.05);
$colors-light-border: #E4E4E4;
$colors-warn: hsla(0, 60.1593625498008%, 50.78431372549019%, 1);
$palette-content-background: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
$palette-text: hsla(240, 100%, 3.1372549019607843%, 1);
$palette-caption: hsla(195, 97.34513274336284%, 44.31372549019608%, 1);
$palette-header-background: linear-gradient(180deg, hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1) 0%, hsla(240, 100%, 3.1372549019607843%, 1) 100%);
$images-logo: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo.png");
$images-logo-2x: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo@2x.png");
$images-logo-3x: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo@3x.png");
$images-logo-width: 156px;
$images-logo-height: 34px;
$images-logo-size: 156px 34px;
$images-logo-white: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite.png");
$images-logo-white-2x: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite@2x.png");
$images-logo-white-3x: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite@3x.png");
$images-logo-white-width: 156px;
$images-logo-white-height: 34px;
$images-logo-white-size: 156px 34px;
$shadows-logo: 0px 1px 16px hsla(240, 100%, 3.1372549019607843%, 0.41000000000000003);
$shadows-logo-filter: drop-shadow(0px 1px 8px hsla(240, 100%, 3.1372549019607843%, 0.41000000000000003));
$margin-top: 40;
$margin-top-px: 40px;
$margin-top-pt: 40pt;
$margin-top-em: 40em;
$margin-top-rem: 40rem;
$margin-bottom: 10;
$margin-bottom-px: 10px;
$margin-bottom-pt: 10pt;
$margin-bottom-em: 10em;
$margin-bottom-rem: 10rem;
$margin-left: 10;
$margin-left-px: 10px;
$margin-left-pt: 10pt;
$margin-left-em: 10em;
$margin-left-rem: 10rem;
$margin-right: 10;
$margin-right-px: 10px;
$margin-right-pt: 10pt;
$margin-right-em: 10em;
$margin-right-rem: 10rem;
$layout-values-spacing-small: 5;
$layout-values-spacing-small-px: 5px;
$layout-values-spacing-small-pt: 5pt;
$layout-values-spacing-small-em: 5em;
$layout-values-spacing-small-rem: 5rem;
$layout-values-spacing-medium: 20;
$layout-values-spacing-medium-px: 20px;
$layout-values-spacing-medium-pt: 20pt;
$layout-values-spacing-medium-em: 20em;
$layout-values-spacing-medium-rem: 20rem;
$layout-values-spacing-large: 40;
$layout-values-spacing-large-px: 40px;
$layout-values-spacing-large-pt: 40pt;
$layout-values-spacing-large-em: 40em;
$layout-values-spacing-large-rem: 40rem;
$strings-title: "revnt";
$strings-caption: "Keep your designs in sync with code";
$strings-helper: "Modify the contents of “src/DesignLanguage.ts” (relative to the root of the Diez project) to see changes to the design language in real time.";

@mixin colors-white-background-color {
  background-color: hsla(0, 0%, 100%, 1);
}

@mixin colors-white-color {
  color: hsla(0, 0%, 100%, 1);
}

@mixin colors-black-background-color {
  background-color: hsla(240, 100%, 3.1372549019607843%, 1);
}

@mixin colors-black-color {
  color: hsla(240, 100%, 3.1372549019607843%, 1);
}

@mixin colors-primary-dark-background-color {
  background-color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
}

@mixin colors-primary-dark-color {
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
}

@mixin colors-primary-darker-background-color {
  background-color: hsla(214.73684210526315, 24.05063291139241%, 15.490196078431373%, 1);
}

@mixin colors-primary-darker-color {
  color: hsla(214.73684210526315, 24.05063291139241%, 15.490196078431373%, 1);
}

@mixin colors-primary-background-color {
  background-color: hsla(206.45962732919256, 74.88372093023257%, 42.15686274509804%, 1);
}

@mixin colors-primary-color {
  color: hsla(206.45962732919256, 74.88372093023257%, 42.15686274509804%, 1);
}

@mixin colors-accent-background-color {
  background-color: hsla(195, 97.34513274336284%, 44.31372549019608%, 1);
}

@mixin colors-accent-color {
  color: hsla(195, 97.34513274336284%, 44.31372549019608%, 1);
}

@mixin colors-background-background-color {
  background-color: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
}

@mixin colors-background-color {
  color: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
}

@mixin colors-background-hint-background-color {
  background-color: hsla(0, 0%, 100%, 0.05);
}

@mixin colors-background-hint-color {
  color: hsla(0, 0%, 100%, 0.05);
}

@mixin colors-warn-background-color {
  background-color: hsla(0, 60.1593625498008%, 50.78431372549019%, 1);
}

@mixin colors-warn-color {
  color: hsla(0, 60.1593625498008%, 50.78431372549019%, 1);
}

@mixin palette-content-background-background-color {
  background-color: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
}

@mixin palette-content-background-color {
  color: hsla(218.99999999999997, 58.82352941176471%, 6.666666666666667%, 1);
}

@mixin palette-text-background-color {
  background-color: hsla(240, 100%, 3.1372549019607843%, 1);
}

@mixin palette-text-color {
  color: hsla(240, 100%, 3.1372549019607843%, 1);
}

@mixin palette-caption-background-color {
  background-color: hsla(195, 97.34513274336284%, 44.31372549019608%, 1);
}

@mixin palette-caption-color {
  color: hsla(195, 97.34513274336284%, 44.31372549019608%, 1);
}

@mixin palette-header-background-background {
  background: linear-gradient(180deg, hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1) 0%, hsla(240, 100%, 3.1372549019607843%, 1) 100%);
}

@mixin palette-header-background-background-image {
  background-image: linear-gradient(180deg, hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1) 0%, hsla(240, 100%, 3.1372549019607843%, 1) 100%);
}

@mixin typography-heading {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 48px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin typography-subheading {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin typography-h4 {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin typography-sub-copy {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin typography-text {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin typography-captions {
  font-family: "SF Pro",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 10.239999771118164px;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 12px;
}

@mixin typography-body {
  font-family: "Poppins",sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12;
  color: hsla(218.91891891891893, 52.112676056338024%, 13.92156862745098%, 1);
  letter-spacing: 0px;
  text-align: start;
  text-decoration: none;
  line-height: 1.6;
}

@mixin images-logo-background-image {
  background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo.png");
  width: 156px;
  height: 34px;
  background-size: 156px 34px;

  @media (min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo@2x.png");
  }

  @media (min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
    background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/Logo@3x.png");
  }
}

@mixin images-logo-white-background-image {
  background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite.png");
  width: 156px;
  height: 34px;
  background-size: 156px 34px;

  @media (min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite@2x.png");
  }

  @media (min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
    background-image: url("/assets/diez-ui-web/static/assets/DesignSystem.figma.contents/images/LogoWhite@3x.png");
  }
}

@mixin shadows-logo-box-shadow {
  box-shadow: 0px 1px 16px hsla(240, 100%, 3.1372549019607843%, 0.41000000000000003);
}

@mixin shadows-logo-text-shadow {
  text-shadow: 0px 1px 16px hsla(240, 100%, 3.1372549019607843%, 0.41000000000000003);
}

@mixin shadows-logo-filter {
  filter: drop-shadow(0px 1px 8px hsla(240, 100%, 3.1372549019607843%, 0.41000000000000003));
}

