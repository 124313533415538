// Move to in-event only
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;
  display: flex;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

[mat-dialog-actions] {
  justify-content: space-between;
}

.ng-scrollbar-thumb.carousel-thumb {
  visibility: hidden;
}

// quill editor; required for bubble theme toolbar to not
// render underneath other html components
.ql-tooltip {
  z-index: 1;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
