// Setup global typography styles here once design conversion is fully complete
@import 'src/assets/diez-ui-web/static/styles';

h2 {
  @include typography-heading;
}

h3 {
  @include typography-subheading;
}

h4 {
  @include typography-h4;
}

p {
  @include typography-body;
}

.info {
  @include typography-captions;
}

.primary {
  color: $colors-primary-dark;
}

.accent {
  color: $colors-accent;
}

.warn,
.error {
  color: $colors-warn;
}

ul {
  padding-left: $layout-values-spacing-medium-px;

  li {
    list-style: disc;
    margin-bottom: $layout-values-spacing-small-px;
  }
}